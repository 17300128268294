/**
 * Implement Dragscroll with Simplebar
 * @requires https://github.com/Grsmto/simplebar
 * @requires https://github.com/asvd/dragscroll
 */

export default (() => {
  /* eslint-disable no-unused-vars, no-undef */
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-dragscroll]'),
    (el) => {
      new SimpleBar(el, {
        autoHide: false,
        classNames: { contentWrapper: 'simplebar-content-wrapper dragscroll' },
      })
      dragscroll.reset()
    }
  )
  /* eslint-enable no-unused-vars, no-undef */
})()

/**
 * Mega dropdown (tabs-like)
 */

export default (() => {
  let dropdownParents = document.querySelectorAll('.dropdown-megamenu')

  for (let s = 0; s < dropdownParents.length; s++) {
    let dropdownPaneTogglers = dropdownParents[s].querySelectorAll(
        '[data-bs-menu-show]'
      ),
      dropdownPanes = dropdownParents[s].querySelectorAll('.dropdown-pane')

    for (let n = 0; n < dropdownPaneTogglers.length; n++) {
      dropdownPaneTogglers[n].addEventListener('click', (e) => {
        for (let i = 0; i < dropdownPaneTogglers.length; i++) {
          dropdownPaneTogglers[i].classList.remove('active')
        }
        for (let m = 0; m < dropdownPanes.length; m++) {
          dropdownPanes[m].classList.remove('shown')
        }
        e.currentTarget.classList.add('active')

        let targetEl = e.currentTarget.dataset.bsMenuShow
        document.querySelector(targetEl).classList.add('shown')
      })
    }
  }
})()

/**
 * Switch content by selected radio-input
 */

export default (() => {
  const radioTabsContainers = document.querySelectorAll('.radio-tabs')

  radioTabsContainers.forEach((container) => {
    container.addEventListener('change', (event) => {
      const target = event.target

      if (target.matches('input[type="radio"][data-target]')) {
        const contentId = target.getAttribute('data-target')
        const activeContent = container.querySelector('.tab-pane.active')

        if (activeContent) {
          activeContent.classList.remove('active')
        }

        const newActiveContent = container.querySelector(`#${contentId}`)

        if (newActiveContent) {
          newActiveContent.classList.add('active')
        }
      }
    })
  })
})()

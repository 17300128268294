/**
 * Form checkbox & radio input highlight on check
 */

export default (() => {
  const formCheckParents = document.querySelectorAll('.form-check-highlight')

  formCheckParents.forEach((parent) => {
    const formCheckInput = parent.querySelector('.form-check-input')

    if (formCheckInput) {
      if (formCheckInput.checked) {
        parent.classList.add('active')
      }

      formCheckInput.addEventListener('change', () => {
        const currentGroupName = formCheckInput.getAttribute('name')

        formCheckParents.forEach((el) => {
          if (
            el.querySelector(`.form-check-input[name="${currentGroupName}"]`)
          ) {
            el.classList.remove('active')
          }
        })

        parent.classList.toggle('active', formCheckInput.checked)
      })
    }
  })
})()

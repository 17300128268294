/**
 * Toggle class of the target element
 */

export default (() => {
  const togglers = document.querySelectorAll('[data-toggle-class]')

  if (togglers.length === 0) return

  togglers.forEach((toggler) => {
    toggler.addEventListener('click', function () {
      const toggleData = JSON.parse(toggler.getAttribute('data-toggle-class'))
      const target = document.querySelector(toggleData.target)

      if (target) {
        if (target.classList.contains(toggleData.className)) {
          target.classList.remove(toggleData.className)
        } else {
          target.classList.add(toggleData.className)
        }
      }
    })
  })
})()

/**
 * Closing all nestings offcanvasses inside main menu
 */

export default (() => {
  const offcanvasMenu = document.getElementById('navbarNavMobile')
  const offcanvasSubmenu = document.querySelectorAll('.offcanvas-submenu')

  if (!offcanvasMenu) return

  offcanvasMenu.addEventListener('hide.bs.offcanvas', () => {
    offcanvasSubmenu.forEach((submenu) => {
      submenu.classList.remove('offcanvas-show')
    })
  })
})()

/**
 * Custom select with improved keyboard navigation and dynamic "binded-content" handling
 * Uses Bootstrap's dropdown component
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
 */

export default (() => {
  // Delegate click handler for custom selects and "binded-content"
  document.addEventListener('click', (event) => {
    const item = event.target.closest('.dropdown-item')
    if (!item) return

    const select = item.closest('.form-custom-select')
    if (!select) return

    // Update the active dropdown item
    const dropdownItems = select.querySelectorAll('.dropdown-item')
    const dropdownToggle = select.querySelector('.form-custom-select-toggle')
    const hiddenInput = select.querySelector('.visually-hidden')

    dropdownItems.forEach((i) => {
      i.classList.remove('active')
      i.setAttribute('aria-selected', 'false')
    })

    item.classList.add('active')
    item.setAttribute('aria-selected', 'true')

    // Update hidden input and button text
    hiddenInput.value = item.getAttribute('data-option')
    dropdownToggle.textContent = item.textContent.trim()

    // Handle "binded-content"
    const bindedContentSelector = item.getAttribute('data-binded-content')
    const allBindedContents = document.querySelectorAll('.binded-content')

    // Hide all binded content
    allBindedContents.forEach((content) => {
      content.classList.remove('active')
    })

    // Show the associated binded content if applicable
    if (bindedContentSelector) {
      const bindedContent = document.querySelector(bindedContentSelector)
      if (bindedContent) {
        bindedContent.classList.add('active')
      } else {
        console.warn(
          `Element with selector "${bindedContentSelector}" not found.`
        )
      }
    }
  })

  // Handle keyboard navigation for custom selects
  document.addEventListener('keydown', (e) => {
    const dropdownToggle = e.target.closest('.form-custom-select-toggle')
    if (!dropdownToggle) return

    const select = dropdownToggle.closest('.form-custom-select')
    if (!select) return

    const dropdownItems = Array.from(
      select.querySelectorAll('.dropdown-item[data-option]')
    )
    const activeOption = select.querySelector('.active[data-option]')
    const key = e.key.toLowerCase()

    // Check if it's a printable character
    if (key.length === 1 && /[a-z0-9\s]/i.test(key)) {
      const nextItem = dropdownItems.find((item) =>
        item.innerText.trim().toLowerCase().startsWith(key)
      )

      if (nextItem) {
        // Deselect current active item
        if (activeOption) {
          activeOption.classList.remove('active')
          activeOption.setAttribute('aria-selected', 'false')
        }

        // Select the matched item
        nextItem.classList.add('active')
        nextItem.setAttribute('aria-selected', 'true')

        // Update hidden input and dropdown toggle
        const hiddenInput = select.querySelector('input.visually-hidden')
        const dropdownToggle = select.querySelector(
          '.form-custom-select-toggle'
        )
        hiddenInput.value = nextItem.getAttribute('data-option')
        dropdownToggle.textContent = nextItem.innerText

        // Scroll into view if needed
        nextItem.scrollIntoView({ block: 'nearest' })
      }
    }
  })

  // Initialize the default state of custom selects on page load
  document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.form-custom-select')
    selects.forEach((select) => {
      const activeItem = select.querySelector('.dropdown-item.active')
      const dropdownToggle = select.querySelector('.form-custom-select-toggle')
      const hiddenInput = select.querySelector('.visually-hidden')

      if (activeItem) {
        hiddenInput.value = activeItem.getAttribute('data-option')
        dropdownToggle.textContent = activeItem.textContent.trim()

        // Set initial state for "binded-content"
        const bindedContentSelector = activeItem.getAttribute(
          'data-binded-content'
        )
        if (bindedContentSelector) {
          const bindedContent = document.querySelector(bindedContentSelector)
          if (bindedContent) {
            bindedContent.classList.add('active')
          }
        }
      }
    })
  })
})()

/**
 * Count & show hidden content elements
 */

export default (() => {
  const hiddenContentElements = document.querySelectorAll('.hidden-content')

  function toggleContent(toggler, hiddenItems, countSpan) {
    hiddenItems.forEach((item) => (item.style.display = 'block'))
    toggler.style.display = 'none'
    countSpan.textContent = hiddenItems.length
  }

  function setupListeners(component) {
    const hiddenItems = component.querySelectorAll('.hidden-content-item'),
      countSpan = component.querySelector('.hidden-content-count'),
      toggler = component.querySelector('.hidden-content-toggle')

    countSpan.textContent = hiddenItems.length

    toggler.addEventListener('click', () =>
      toggleContent(toggler, hiddenItems, countSpan)
    )
  }

  hiddenContentElements.forEach(setupListeners)
})()
